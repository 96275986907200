<template>
  <div class="main user-layout-register">
    <div class="main_page">
      <div class="main_l">
        <a-descriptions title="监控参数">
          <a-descriptions-item label="总洗车量">
            {{ immGetPub01.zxcl || immGetPub01.zxcl == 0 ? immGetPub01.zxcl : '--' }}
            <div
              class="video_t_l"
              @click="handleBack()"
            >返回</div>
          </a-descriptions-item>
          <a-descriptions-item label="今日洗车量">
            {{ immGetPub01.jrxcsl || immGetPub01.jrxcsl == 0 ? immGetPub01.jrxcsl : '--' }}
          </a-descriptions-item>
          <a-descriptions-item label="M1模式风机干次数">
            {{ immGetPub01.m1_FGCS || immGetPub01.m1_FGCS == 0 ? immGetPub01.m1_FGCS : '--' }}
          </a-descriptions-item>
          <a-descriptions-item label="M2模式风机干次数">
            {{ immGetPub01.m2_FGCS || immGetPub01.m2_FGCS == 0 ? immGetPub01.m2_FGCS : '--' }}
          </a-descriptions-item>
        </a-descriptions>
        <div
          class="video_s"
          v-if="cameraInfo1 && cameraInfo1.name"
        >
          <div class="video_t">
            <div
              class="video_t_l"
              @click="handleQie(1)"
            >切换</div>
            <div>{{ cameraInfo1.name }}</div>
            <div class="video_t_r"></div>
          </div>
          <div
            id="playWind"
            class="video_k"
          ></div>
        </div>
        <div
          v-else
          class="video_s video_s_u"
        >
          暂无摄像头
        </div>
        <div
          class="control"
          style="margin-right:30px;"
        >
          <div class="control_t">
            <!-- <a-descriptions title="设备控制">
            </a-descriptions> -->
            <div class="control_t_l">设备控制</div>
            <div class="control_r">
              <div>开始：{{ immGetPub01.xcjztxs_M385 ? '开' : '关' }}</div>
              <div style="margin-left:20px">虚拟支付：{{ immGetPub01.zfzt_M999 ? '开' : '关' }}</div>
            </div>
          </div>
          <div class="control_c">
            <div class="control_l">
              <div class="control_1">
                <div
                  :class="immGetPub01.m1_DQXZDMS ? 'control_i_a' : 'control_i'"
                  @click="handleControl(`是否确定 ${immGetPub01.m1_DQXZDMS ? '关闭' : '启动'}模式1洗车`, '/bao/bao-dev/szm1')"
                > 模式1</div>
              </div>
              <div class="control_1 control_2">
                <div
                  :class="immGetPub01.m2_DQXZDMS ? 'control_i_a' : 'control_i'"
                  @click="handleControl(`是否确定 ${immGetPub01.m2_DQXZDMS ? '关闭' : '启动'}模式2洗车`, '/bao/bao-dev/szm2')"
                > 模式2</div>
              </div>
            </div>
            <div class="control_r">
              <div class="control_r_i">
                <div class="control_1 control_4">
                  <div
                    class="control_i"
                    @click="handleControl(`是否执行一键低泡`, '/bao/bao-dev/ms01')"
                  > 低泡</div>
                </div>
                <div class="control_1 control_4">
                  <div
                    class="control_i"
                    @click="handleControl(`是否执行一键高泡`, '/bao/bao-dev/ms02')"
                  > 高泡</div>
                </div>
                <div class="control_1 control_4">
                  <div
                    class="control_i"
                    @click="handleControl(`是否执行一键冲水`, '/bao/bao-dev/ms05')"
                  > 冲水</div>
                </div>
              </div>
              <div class="control_r_i control_2 control_4">
                <div class="control_1">
                  <div
                    class="control_i"
                    @click="handleControl(`是否执行一键水蜡`, '/bao/bao-dev/ms03')"
                  > 水蜡</div>
                </div>
                <div
                  class="control_1 control_4"
                  style="background:transparent"
                >
                </div>
                <div class="control_1 control_4">
                  <div
                    class="control_i "
                    @click="handleControl(`是否执行一键风干`, '/bao/bao-dev/ms04')"
                  > 风干</div>
                </div>
              </div>
            </div>
          </div>
          <div class="foot_con">
            当前：
            <div class="foot_k">
              预充:{{ one !== -1 ? '开' : '关' }}
            </div>
            <div class="foot_k">
              低泡:{{ two !== -1 ? '开' : '关' }}
            </div>
            <div class="foot_k">
              冲水1:{{ three !== -1 ? '开' : '关' }}
            </div>
            <div class="foot_k">
              高泡:{{ four !== -1 ? '开' : '关' }}
            </div>
            <div class="foot_k">
              冲水2:{{ five !== -1 ? '开' : '关' }}
            </div>
            <div class="foot_k">
              水蜡:{{ six !== -1 ? '开' : '关' }}
            </div>
            <div class="foot_k">
              风干:{{ sevent !== -1 ? '开' : '关' }}
            </div>
            <!-- <div class="control_1">
              <div :class="immGetPub01.m1_DQXZDMS ? 'control_i_a' : 'control_i'"> 预充</div>
            </div>
            <div class="control_1">
              <div :class="immGetPub01.m1_DQXZDMS ? 'control_i_a' : 'control_i'"> 低泡</div>
            </div>
            <div class="control_1">
              <div :class="immGetPub01.m1_DQXZDMS ? 'control_i_a' : 'control_i'"> 冲水1</div>
            </div>
            <div class="control_1">
              <div :class="immGetPub01.m1_DQXZDMS ? 'control_i_a' : 'control_i'"> 高泡</div>
            </div>
            <div class="control_1">
              <div :class="immGetPub01.m1_DQXZDMS ? 'control_i_a' : 'control_i'"> 冲水2</div>
            </div>
            <div class="control_1">
              <div :class="immGetPub01.m1_DQXZDMS ? 'control_i_a' : 'control_i'"> 水蜡</div>
            </div>
            <div class="control_1">
              <div :class="immGetPub01.m1_DQXZDMS ? 'control_i_a' : 'control_i'"> 风干</div>
            </div> -->
            <div class="control_1 foot_k">
              <div
                @click="handleControl(`是否确定停止`, '/bao/bao-dev/one1')"
                :class="immGetPub01.yctz ? 'control_i_a' : ''"
              > 停止</div>
            </div>
            <div class="control_1 foot_k">
              <div
                @click="handleControl(`是否一键复位`, '/bao/bao-dev/one2')"
                :class="immGetPub01.yjfw ? 'control_i_a' : ''"
              > 复位</div>
            </div>
          </div>
        </div>
      </div>
      <div class="main_l">
        <a-descriptions title="洗车条件">
          <a-descriptions-item label="天车原点接近">
            {{ immGetPub01.x17_TCYDJJ ? '正常' : '未接近' }}
          </a-descriptions-item>
          <a-descriptions-item label="喷杆原点接近">
            {{ immGetPub01.x14_PGYDJJ ? '正常' : '未接近' }}
          </a-descriptions-item>
          <a-descriptions-item label="超出光电">
            {{ immGetPub01.x4_CCGD ? '正常' : '超出' }}
          </a-descriptions-item>
          <a-descriptions-item label="到位光电">
            {{ immGetPub01.x5_DWGD ? '正常' : '停车到位' }}
          </a-descriptions-item>
          <a-descriptions-item label="入口光电">
            {{ immGetPub01.X6_RKGD ? '正常' : '未到位' }}
          </a-descriptions-item>
        </a-descriptions>
        <!-- <div class="">
          1212
        </div> -->
        <div
          class="video_s"
          v-if="cameraInfo2 && cameraInfo2.name"
        >
          <div class="video_t">
            <div
              class="video_t_l"
              @click="handleQie(2)"
            >切换</div>
            <div>{{ cameraInfo2.name }}</div>
            <div class="video_t_r"></div>
          </div>
          <div
            id="playWinds"
            class="video_k"
          ></div>
        </div>
        <div
          v-else
          class="video_s video_s_u"
        >
          暂无摄像头
        </div>
        <div class="control">
          <div class="control_t">
            <div class="control_t_l">控制</div>
            <div class="control_r">
              <span class="control_r_s">锁</span> <a-switch v-model="open" />
            </div>
          </div>
          <div class="control_c">
            <div class="con">
              <span class="con_s">门控</span>
              <div class="con_r">
                <div class="con_r_t ">
                  <div
                    :class="open ? 'control_1' : 'control_1 control_3'"
                    @mousedown="handleDown('ycmss', '/bao/bao-dev/one3')"
                    @mouseup="handleUp('ycmss', '/bao/bao-dev/one3')"
                  >
                    <div :class="immGetPub01.ycmss ? 'control_i_a' : ''"> 上升</div>
                  </div>
                  <span>状态：{{ immGetPub01.jlmzt1 ? '开' : '关' }}</span>
                </div>
                <div class="con_r_t control_4">
                  <div
                    :class="open ? 'control_1' : 'control_1 control_3'"
                    @mousedown="handleDown('ycmxj', '/bao/bao-dev/one3')"
                    @mouseup="handleUp('ycmxj', '/bao/bao-dev/one3')"
                  >
                    <div :class="immGetPub01.ycmxj ? 'control_i_a' : ''"> 下降</div>
                  </div>
                  <span>状态：{{ immGetPub01.jlmzt2 ? '开' : '关' }}</span>
                </div>
              </div>
            </div>
          </div>
          <div
            class="control_c"
            style="margin-top:30px"
          >
            <div class="con">
              <span class="con_s">天车</span>
              <div class="con_r">
                <div
                  :class="open ? 'control_1' : 'control_1 control_3'"
                  @mousedown="handleDown('ycfw_TCXQ', '/bao/bao-dev/kz02')"
                  @mouseup="handleUp('ycfw_TCXQ', '/bao/bao-dev/kz02')"
                >
                  <div :class="immGetPub01.ycfw_TCXQ ? 'control_i_a' : ''"> 前</div>
                </div>
                <div
                  :class="open ? 'control_1 control_4' : 'control_1 control_3 control_4'"
                  @mousedown="handleDown('ycfw_TCXH', '/bao/bao-dev/kz03')"
                  @mouseup="handleUp('ycfw_TCXH', '/bao/bao-dev/kz03')"
                >
                  <div :class="immGetPub01.ycfw_TCXH ? 'control_i_a' : ''"> 后</div>
                </div>
                <div
                  :class="open ? 'control_1 control_4' : 'control_1 control_3 control_4'"
                  @mousedown="handleDown('ycfw_SBZZ', '/bao/bao-dev/kz04')"
                  @mouseup="handleUp('ycfw_SBZZ', '/bao/bao-dev/kz04')"
                >
                  <div :class="immGetPub01.ycfw_SBZZ ? 'control_i_a' : ''"> 正转</div>
                </div>
                <div
                  :class="open ? 'control_1 control_4' : 'control_1 control_3 control_4'"
                  @mousedown="handleDown('ycfw_SBFZ', '/bao/bao-dev/kz01')"
                  @mouseup="handleUp('ycfw_SBFZ', '/bao/bao-dev/kz01')"
                >
                  <div :class="immGetPub01.ycfw_SBFZ ? 'control_i_a' : ''"> 反转</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { getDev, controlPublic, devDetail, cameraPage, controlPublics } from '@/api/device'
// import 'video.js/dist/video-js.css'
// import 'vue-video-player/src/custom-theme.css'
// import 'videojs-contrib-hls'
// import { videoPlayer } from 'vue-video-player'
import Vue from 'vue'
import ezuikit from '@/utils/ezuikit.js'
let client = null
var EZOPENDemo = null
var EZOPENDemos = null
var ezopenInit
var ezopenInit1
export default {
  name: 'Register',
  // components: { videoPlayer },
  data () {
    return {
      devId: '',
      info: {},
      immGetPub01: {},
      immGetPub02: {},
      devInfo: {},
      video: 'hello-video',
      playerOptions: {
        height: '260',
        language: 'en',
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'application/x-mpegURL',
            withCredentials: false,
            src: 'http://hls01open.ys7.com/openlive/b1a3def8df8344fe9483fbd06920d7bd.hd.m3u8'
          }
        ],
        techOrder: ['html5'],
        flash: { hls: { withCredentials: false } },
        html5: { hls: { withCredentials: false } },
        autoplay: true,
        controls: true
      },
      one: 0,
      two: 0,
      three: 0,
      four: 0,
      five: 0,
      six: 0,
      sevent: 0,
      open: false,
      longPressTimer: null,
      longPressActive: false,
      s1: '',
      s2: '',
      s3: '',
      s4: '',
      s5: '',
      s6: '',
      subscribe: [],
      cameraInfo2: {},
      cameraInfo1: {},
      dataList: [],
      showId: 1,
      showIds: 1
    }
  },
  computed: {},
  created () {
    let index = window.location.href.indexOf('id=')
    // console.log(window.location.href.substring(index + 3), '大大大大大')
    this.devId = window.location.href.substring(index + 3)
    // 查询详情
    this.getDetail()
    this.getDevDetail()
  },
  beforeDestroy () {
    client.end()
    this.getClose()
  },
  methods: {
    handleBack(){
      // window.history.back()
    },
    handleQie (e) {
      console.log(e, '大大大大大=============');
      if (e == 1) {
        console.log(this.showId, 'this.showIdthis.showId');
        var stopPromise = EZOPENDemo.stop()
        stopPromise.then((data) => {
          console.log('promise 获取 数据', data)
        })
        EZOPENDemo.stop()
        document.getElementById('playWind').innerHTML = ''
        if (this.showId == 1) {
          this.showId = 2
        } else {
          this.showId = 1
        }
        console.log(this.showId, '滴滴滴滴滴滴');
        ezopenInit()
      } else {
        var stopPromises = EZOPENDemos.stop()
        stopPromises.then((data) => {
          console.log('promise 获取 数据', data)
        })
        // var destroyPromises = EZOPENDemos.destroy()
        // destroyPromises.then((data) => {
        //   console.log('promise 获取 数据', data)
        // })
        EZOPENDemos.stop()
        document.getElementById('playWinds').innerHTML = ''
        if (this.showIds == 1) {
          this.showIds = 2
        } else {
          this.showIds = 1
        }
        ezopenInit1()
      }
    },
    getClose () {
      if (this.dataList.length) {
        var stopPromise = EZOPENDemo.stop()
        stopPromise.then((data) => {
          console.log('promise 获取 数据', data)
        })
        EZOPENDemo.stop()
        document.getElementById('playWind').innerHTML = ''
        if (this.dataList.length == 2) {
          var stopPromises = EZOPENDemos.stop()
          stopPromises.then((data) => {
            console.log('promise 获取 数据', data)
          })
          // var destroyPromises = EZOPENDemos.destroy()
          // destroyPromises.then((data) => {
          //   console.log('promise 获取 数据', data)
          // })
          EZOPENDemos.stop()
          document.getElementById('playWinds').innerHTML = ''
        }
      }

    },

    // 查看摄像头
    getCamera (devId) {
      cameraPage({ devId }).then(res => {
        let date = res.data.records
        console.log(date, 'datedatedatedate');
        this.dataList = date
        let that = this
        if (date.length) {
          this.cameraInfo1 = date[0]
          ezopenInit = () => {
            EZOPENDemo = new EZUIKit.EZUIKitPlayer({
              id: 'playWind',
              width: '450',
              height: '250',
              template: 'pcLive',
              url: 'ezopen://open.ys7.com/' + date[0].code + '/' + that.showId + '.live',
              accessToken: date[0].accessToken
            })
          }
          ezopenInit()
          if (date.length == 2) {
            this.cameraInfo1 = date[0]
            this.cameraInfo2 = date[1]
            ezopenInit1 = () => {
              EZOPENDemos = new EZUIKit.EZUIKitPlayer({
                id: 'playWinds',
                width: '450',
                height: '250',
                template: 'pcLive',
                url: 'ezopen://open.ys7.com/' + date[1].code + '/' + that.showIds + '.live',
                accessToken: date[1].accessToken
              })
            }
            ezopenInit1()
          }
        }
      })
    },
    getDevDetail () {
      devDetail({ id: this.devId }).then(res => {
        res = res.data
        this.devInfo = res
        // this.subscribe = res.devCode + '/immGetSub01'
        this.subscribe = [res.devCode + '/web/pub/01', res.devCode + '/web/pub/02']
        console.log(this.subscribe, 'this.subscribethis.subscribethis.subscribe');
        this.connectMqtt()
        this.getCamera(this.devId)
      })
    },
    /**
 * @desc mqtt连接
 * @author 刘
 * @time 2023年06月02日 18:31:19 星期五
 */
    connectMqtt () {
      const that = this
      var mqtt = require('mqtt/dist/mqtt.js')
      // client = mqtt.connect('ws://emqx.zzcyi.cn:8083/bao/bao-dev')
      // const mqttUrl = 'ws://139.196.79.66:7084/bao/bao-dev'
      const mqttUrl = 'ws://139.196.79.66:7084/mqtt'
      // client = mqtt.connect('ws://47.104.102.88:8083/bao/bao-dev')
      client = mqtt.connect(mqttUrl, {
        protocolVersion: 4,
        reconnectPeriod: 100,
        connectTimeout: 30 * 1000,
        resubscribe: true,
        keepalive: 3,
        clientId:
          'mqttjs_' +
          Math.random()
            .toString(16)
            .substr(2, 8)
      })
      client.on('connect', function () {
        console.log('on connect', new Date().getTime())
        console.log('主题>>>>', that.subscribe)
        client.subscribe(that.subscribe, function (err, granted) {
          console.log('订阅主题>>>>>err>>>>', err, that.subscribe)
          console.log('订阅主题>>>>>granted>>>>', granted)
          if (!err) {
            console.log('订阅成功>>>>')
          }
        })
      }).on('reconnect', function () {
        // uni.showLoading({
        // 	title:'加载中...',
        // 	mask:true
        // })
        // setTimeout(function() {
        // 	uni.hideLoading()
        // }, 3000);
        console.log('重新连接', new Date().getTime())
      }).on('close', function (err) {
        // that.setIsMqttConnect(false)
        console.log('链接断开>>>>', err)
      }).on('error', function (err) {
        console.log('on error', err)
      }).on('end', function () {
        // that.setIsMqttConnect(false)
        console.log('on end')
      }).on('message', function (topic, message) {
        console.log('mqtt消息', message.toString())
        // alert(message.toString())
        // that.getDetail()
        let num = JSON.parse(message.toString());
        console.log(num, 'numnumnumnum====');
        if (num.topic.indexOf('/web/pub/01') !== -1) {
          that.immGetPub01 = num
          that.$forceUpdate()
          console.log(that.immGetPub01, 'that.immGetPub01that.immGetPub01');
        } else if (num.topic.indexOf('/web/pub/02') !== -1) {
          that.immGetPub02 = num
          that.$forceUpdate()
          console.log(that.immGetPub02, 'that.immGetimmGetPub02immGetPub02immGetPub02Pub01that.immGetPub01');
        }
        // try {
        //   const messageObj = JSON.parse(message)
        //   console.log('接受到的消息>>>', messageObj)
        //   // logType为"sendToDev"--向设备发送信息
        //   // logType为"devReply"--设备回复
        //   // logType为"devLogin"--设备上线
        //   that.setData(messageObj)
        // } catch (e) {
        //   // TODO handle the exception
        // }
      })
    },
    // 点击控制
    handleControl (e, url) {
      console.log(e, '滴滴滴滴滴滴');

      let that = this
      console.log(that.devId, ' that.devId that.devId');
      this.$confirm({
        title: '提示',
        content: e,
        onOk () {
          controlPublic(url, { devId: that.devId }).then(res => {

          })
        },
        onCancel () { },
      });
    },
    // 点击长按事件
    handleDown (e, url) {
      // 设置一个定时器，如果用户持续按住，则执行长按操作
      if (!this.open) return this.$message.error('请检查现场情况在打开锁')
      if (this.longPressTimer) {
        clearTimeout(this.longPressTimer);
        this.longPressTimer = null
      }
      if (this.immGetPub01[e]) {
        this.handleUp(e, url, 1)
      } else {
        if (e == 'ycfw_TCXQ' && this.immGetPub01.ycfw_TCXH) return this.$message.error('请先关闭向后')
        if (e == 'ycfw_TCXQ' && this.immGetPub01.ycfw_SBFZ) return this.$message.error('请先关闭反转')
        if (e == 'ycfw_TCXQ' && this.immGetPub01.ycfw_SBZZ) return this.$message.error('请先关闭正转')
        if (e == 'ycfw_TCXH' && this.immGetPub01.ycfw_TCXQ) return this.$message.error('请先关闭向前')
        if (e == 'ycfw_TCXH' && this.immGetPub01.ycfw_SBFZ) return this.$message.error('请先关闭反转')
        if (e == 'ycfw_TCXH' && this.immGetPub01.ycfw_SBZZ) return this.$message.error('请先关闭正转')
        if (e == 'ycfw_SBFZ' && this.immGetPub01.ycfw_TCXQ) return this.$message.error('请先关闭向前')
        if (e == 'ycfw_SBFZ' && this.immGetPub01.ycfw_TCXH) return this.$message.error('请先关闭向后')
        if (e == 'ycfw_SBFZ' && this.immGetPub01.ycfw_SBZZ) return this.$message.error('请先关闭正转')
        if (e == 'ycfw_SBZZ' && this.immGetPub01.ycfw_TCXQ) return this.$message.error('请先关闭向前')
        if (e == 'ycfw_SBZZ' && this.immGetPub01.ycfw_TCXH) return this.$message.error('请先关闭向后')
        if (e == 'ycfw_SBZZ' && this.immGetPub01.ycfw_SBFZ) return this.$message.error('请先关闭反转')
        this.longPressTimer = setTimeout(() => {
          // 执行长按后的操作
          // console.log('Long press triggered');
          this.immGetPub01[e] = true
          if (e == 'ycfw_TCXQ' || e == 'ycfw_TCXH' || e == 'ycfw_SBFZ' || e == 'ycfw_SBZZ') {
            // s3 向前 后 正 反
            controlPublics(url, { id: this.devId, open: true }).then(res => {

            })
          } else if (e == 'ycmss') {
            // s1 升 
            controlPublic(url, { devId: this.devId, YCMSS: true }).then(res => {

            })
          } else {
            // s2 降
            controlPublic(url, { devId: this.devId, YCMXJ: true }).then(res => {

            })
          }
          // if (e == 1) {
          //   console.log(this.s1, '对对对的多多多急急急吗');
          // }
          // clearTimeout(this.longPressTimer);

        }, 800); // 长按1秒
      }

    },
    // 结束长按
    handleUp (e, url) {
      console.log('handleUp>>>>');
      if (!this.open) return
      // 清除定时器
      clearTimeout(this.longPressTimer);
      // if (e == 1) {
      //   this.s1 = false
      // }
      console.log('handleUp>>>>111333');
      if (!this.immGetPub01[e]) return
      this.immGetPub01[e] = false
      console.log('handleUp>>>>111');
      if (e == 'ycfw_TCXQ' || e == 'ycfw_TCXH' || e == 'ycfw_SBFZ' || e == 'ycfw_SBZZ') {
        // s3 向前 后 正 反
        controlPublics(url, { id: this.devId, open: false }).then(res => {
        })
      } else if (e == 'ycmss') {
        // s1 升 
        controlPublic(url, { devId: this.devId, YCMSS: false }).then(res => {

        })
      } else {
        // s2 降
        controlPublic(url, { devId: this.devId, YCMXJ: false }).then(res => {

        })
      }
      this.longPressActive = false
    },
    getDetail () {
      getDev({ devId: this.devId }).then(res => {
        console.log(res, '滴滴滴滴滴滴1111');
        res = res.data
        this.one = res.currentStep.indexOf(1)
        this.two = res.currentStep.indexOf(2)
        this.three = res.currentStep.indexOf(3)
        this.four = res.currentStep.indexOf(4)
        this.five = res.currentStep.indexOf(5)
        this.six = res.currentStep.indexOf(6)
        this.sevent = res.currentStep.indexOf(7)
        console.log(this.one, 'dddd嘟嘟嘟嘟')
        // this.currentStep = .split(',')
        this.info = res
        this.immGetPub01 = res.immGetPub01
        this.immGetPub02 = res.immGetPub02
      })
    }
  }
}
</script>

<style lang="less" scoped>
.main_page {
  display: flex;

  .main_l {
    width: 50%;

    .control {
      margin-top: 30px;
      width: 100%;


      .control_t {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-right: 30px;
        margin-bottom: 20px;

        .control_t_l {
          font-size: 20px;
          font-weight: bold;
          color: #000;
        }

        .control_r {
          display: flex;
          align-items: center;

          .control_r_s {
            display: inline-block;
            margin-right: 20px;
          }
        }
      }

      .control_c {
        display: flex;

        // justify-content: space-between;
        .control_r {
          .control_r_i {
            display: flex;
            justify-content: space-between;
            align-items: center;
            // width: 500px;
          }
        }

        .con {
          display: flex;
          align-items: center;
          width: 100%;

          .con_s {
            width: 80px;
            display: inline-block;
            // margin-left: 20px;
          }

          .con_r {
            margin-left: 30px;
            // width: 800px;
            display: flex;
            align-items: center;
            flex: 1;
            // background: red;
            // justify-content: space-between;

            .con_r_t {
              display: flex;
              align-items: center;

              span {
                display: inline-block;
                margin-left: 20px;
              }
            }
          }
        }


        .control_2 {
          margin-top: 30px;
        }
      }
    }
  }

  .control_1 {
    display: flex;
    cursor: pointer;
    width: 100px;
    height: 30px;
    background: #e6f0ff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;

    div {
      width: 100px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .control_i_a {
      background: #2878ff;
      color: #ffffff;
    }
  }

  .control_3 {
    background: #bfbfbf;
  }

  .foot_con {
    margin-top: 30px;
    display: flex;
    align-items: center;

    .foot_k {
      margin-left: 20px;
    }
  }

}

.control_4 {
  margin-left: 30px;
}

.video_s {
  width: 450px;
  height: 350px;
  position: relative;

  .video_t {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .video_t_l {
      width: 80px;
      height: 30px;
      display: flex;
      align-items: center;
      border-radius: 3px;
      cursor: pointer;
      color: #ffffff;
      justify-content: center;
      background: #2878ff;
      z-index: 666;
    }

    .video_t_r {
      width: 80px;
      height: 30px;
    }
  }

  .video_k {
    width: 450px;
    height: 350px;
  }
}

.control_l {
  padding-right: 30px;
  border-right: 1px solid #eee;
}

.video_s_u {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
